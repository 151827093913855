import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Modal } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { slideUp, slideDown } from '../../components/Animasi/anim';
import httpService from "../../services/httpService";
import { getJwt } from "../../services/authService";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const url = process.env.REACT_APP_API_URL
var idLocale = require('moment/locale/id'); 
moment.locale('id', idLocale);

class UnitUsahaTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            aksi: "Detail",
            modal_terima: false,
            modal_tolak: false,
            notes: ''
        };
        this.toggleExpander = this.toggleExpander.bind(this)
        this.toggleModalTerima = this.toggleModalTerima.bind(this)
        this.toggleModalTolak = this.toggleModalTolak.bind(this)
    }

    toggleExpander(e) {
        if (!this.state.expanded) {
            this.setState({expanded: true, aksi: "Tutup"}, () => {
                if (this.refs.expanderBody) {
                    slideDown(this.refs.expanderBody);
                }
            })
        } else {
            slideUp(this.refs.expanderBody, {
                onComplete: () => { this.setState({expanded: false, aksi: "Detail"}); }
            })
        }
    }

    toggleModalTerima() {
        this.setState(prevState => ({
          modal_terima: !prevState.modal_terima
        }));
    }

    toggleModalTolak() {
        this.setState(prevState => ({
          modal_tolak: !prevState.modal_tolak
        }));
    }

    render() {
        const { data, handleChange, handleTerima, handleTolak } = this.props
        const date = moment(data.updated_at).format('LLLL'); 
        return [
            <tr key={data.id} onClick={this.toggleExpander}>
                <td className="text-center"><img src={url + data.photo} className="avatar-sm" alt="ava_unit_usaha"></img></td>
                <td>{data.created_at !== null ? moment(data.created_at).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                <td>{data.name}</td>
                <td>{data.level}</td>
                <td>{data.member[0].user.profile.full_name}</td>
                <td>
                    <button className="btn btn-primary">{this.state.aksi}</button>
                </td>
            </tr>,
            this.state.expanded && (
                <tr className="expandable" key="tr-expander">
                    <td style={{backgroundColor: '#F6F6F6'}} colSpan="6">
                        <div ref="expanderBody">
                            <Row>
                                <Col md="5" className="mt-3">
                                    <Row>
                                        <Col>
                                            <span className="text-muted">Dokumen Unit Usaha</span>
                                        </Col>
                                        <Col>
                                            {data.attachment.length > 0 ? (
                                                data.attachment.map(item => { return (
                                                    <a href={url + item.name} target="_blank" key={item.id} rel="noopener noreferrer"><p>{item.file_name}</p></a>
                                                )})
                                            ) : (
                                                <p>No File Available</p>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="4">
                                    <table>
                                        <thead>
                                            <tr>
                                                <td className="text-muted">Waktu Permintaan</td>
                                                <th style={{borderBottom: 'transparent'}}>{date}</th>
                                            </tr>
                                            <tr>
                                                <td className="text-muted">Deskripsi</td>
                                                <th style={{borderBottom: 'transparent'}}>{data.description}</th>
                                            </tr>
                                        </thead>
                                        <tbody></tbody>
                                    </table>
                                </Col>
                                <Col md="3">
                                    <div className="mt-3">
                                        <p><b>Terima Permintaan Verifikasi?</b></p>
                                        <div className="d-flex">
                                            <button className="btn btn-danger mr-2" onClick={this.toggleModalTolak}>Tolak</button>
                                            <button className="btn btn-primary mr-2" onClick={this.toggleModalTerima}>Terima</button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Modal
                                isOpen={this.state.modal_terima}
                                toggle={this.toggleModalTerima}
                                >
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0" id="myModalLabel">
                                    Terima Permintaan Verifikasi
                                </h5>
                                    <button
                                    type="button"
                                    onClick={() =>
                                        this.setState({ modal_terima: false })
                                    }
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    >
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>Anda akan menerima permintaan verifikasi, pastikan data yang diberikan sesuai dengan syarat dan ketentuan yang berlaku</p>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-danger waves-effect"
                                        data-dismiss="modal"
                                        onClick={this.toggleModalTerima}
                                    >
                                        Batal
                                    </button>
                                    <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => handleTerima(data, this.state.notes)}
                                    >
                                        Terima
                                    </button>
                                </div>
                            </Modal>
                            <Modal
                                isOpen={this.state.modal_tolak}
                                toggle={this.toggleModalTolak}
                                >
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0" id="myModalLabel">
                                    Tolak Permintaan Verifikasi
                                </h5>
                                    <button
                                    type="button"
                                    onClick={() =>
                                        this.setState({ modal_tolak: false })
                                    }
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    >
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className="form-label">Alasan Penolakan</label>
                                        <textarea className="form-control" name="notes" onChange={(e) => handleChange(e)}></textarea>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-danger waves-effect"
                                        data-dismiss="modal"
                                        onClick={this.toggleModalTolak}
                                    >
                                        Batal
                                    </button>
                                    <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => handleTolak(data, this.state.notes)}
                                    >
                                        Kirim
                                    </button>
                                </div>
                            </Modal>
                        </div>
                    </td>
                </tr>
            )
        ]
    }
}

class UnitUsaha extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unit_usaha: [],
            allData: [],
            filter: [],
            level: 'all',
            notes: ''
        };
        this.handleFilter = this.handleFilter.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.submitFilter = this.submitFilter.bind(this);
        this.getData = this.getData.bind(this);
    }

    async getData() {
        const apiUrl = url + 'unitUsaha/get/upgrade'
        httpService.get(apiUrl, {
            headers: {
                Authorization: "Bearer "+getJwt()
            }
        })
        .then(res => {
            const data = res.data.data
            const categoryFilter = [];
            const filterLevel = [];
            const totalLevel = [];

            data.forEach(item => {
                if (filterLevel.indexOf(item.level) === -1) {
                    filterLevel.push(item.level);
                    totalLevel.push(0);
                }

                if (filterLevel.indexOf(item.level) > -1) {
                    totalLevel[filterLevel.indexOf(item.level)] += 1;
                }
            })

            for (var i = 0; i < filterLevel.length; i++) {
                const obj = {
                    level: filterLevel[i],
                    count: totalLevel[i]
                }
                categoryFilter.push(obj)
            }

            this.setState({ unit_usaha: data, filter: categoryFilter })
            this.getIsi(data)
        })
    }

    getIsi(value) {
        this.setState({ allData: value })
    }

    async componentDidMount() {
        await this.getData()
    }

    handleChange(e) {
        this.setState({
            notes: e.target.value
        })
    }

    handleTerima(data, notes) {
        const apiUrl = url + 'unitUsaha/upgrade/level'
        const obj = {
            unit_usaha_id: data.id,
            rejected: false,
            level: data.level + 1,
            note: notes
        }
        httpService.post(apiUrl, obj, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            toast.success(res.data.message);
            this.getData();
        })
        .catch(err => {
            if (err.response) {
                toast.error(err.response.data.errors[0].message)
            } else {
                toast.error('Network Error !!!')
            }
        })
    }

    handleTolak(data, notes) {
        const apiUrl = url + 'unitUsaha/upgrade/level'
        const obj = {
            unit_usaha_id: data.id,
            rejected: true,
            level: data.level,
            note: notes
        }
        httpService.post(apiUrl, obj, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            this.getData()
            toast.success(res.data.message);
        })
        .catch(err => {
            if (err.response) {
                toast.error(err.response.data.errors[0].message)
            } else {
                toast.error('Network Error !!!')
            }
        })
    }

    handleFilter(e) {
        const value = e.target.value;
        this.setState({ level: value })
    }

    submitFilter() {
        const { unit_usaha, level } = this.state
        const filterLevel = [];
        if (level === "0") {
            unit_usaha.forEach(a => {
                if (a.level === 0) 
                    filterLevel.push(a)
                
            })
        } else if (level === "1") {
            unit_usaha.forEach(a => {
                if (a.level === 1) 
                    filterLevel.push(a)
                
            })
        } else if (level === "all") {
            unit_usaha.forEach(a => {
                filterLevel.push(a)
            })
        } 
        this.getIsi(filterLevel)
    }

    render() {
        const { allData, filter, notes } = this.state
        return (
            <React.Fragment>
                <ToastContainer />
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Verifikasi" breadcrumbItem="Unit Usaha" />
                        <Card>
                            <CardBody>
                                <h5 className="mb-3">Verifikasi Unit Usaha</h5>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col md="4">
                                                <div>
                                                    <select className="form-control" onChange={this.handleFilter}>
                                                        <option value="all">Semua</option>
                                                        {filter.map(item => <option value={item.level}>Level {item.level}</option>)}
                                                    </select>
                                                </div>
                                            </Col>
                                            <Col>
                                                <button className="btn btn-primary" onClick={this.submitFilter}>Apply</button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <div className="float-right">
                                            <Link to={"/riwayat-unitusaha"}>
                                                <button className="btn btn-primary">Riwayat</button>
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="table-responsive mt-3">
                                    <table className="table w-100">
                                        <thead>
                                            <tr>
                                                <th className="text-center">#</th>
                                                <th>Tanggal Permintaan</th>
                                                <th>Unit Usaha</th>
                                                <th>Level</th>
                                                <th>Ketua Unit Usaha</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allData.length > 0 ? (
                                                    allData.map((i, index) => <UnitUsahaTable key={index} data={i} handleChange={(e) => this.handleChange(e)} handleTerima={() => this.handleTerima(i, notes)} handleTolak={() => this.handleTolak(i, notes)} />)
                                            ) : (
                                                <tr>
                                                    <td colSpan="6" className="text-center">No Data Available</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default UnitUsaha;