import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Modal, UncontrolledTooltip } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "../../assets/scss/datatables.scss";
import { MDBDataTable } from "mdbreact";
import httpService from '../../services/httpService';
import { getJwt } from '../../services/authService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/css/pagination.css';
import moment from 'moment';
import Swal from "sweetalert2";
import Axios from "axios";
import noImage from '../../assets/images/no-photo.png';

const url = process.env.REACT_APP_API_URL
const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g,'.'));

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_standard: false,
            modal_profile: false,
            modal_bills: false,
            modal_edit: false,
            user_id: '',
            user_name: '',
            type: 'plus',
            all_user: [],
            data_wallet: [],
            user_profile: {},
            user: {
                columns: [
                    {
                      label: "No",
                      field: "no",
                      sort: "asc",
                      width: 150
                    },
                    {
                        label: "Tanggal Create Account",
                        field: "tanggal",
                        sort: "asc",
                        width: 150
                      },
                    {
                      label: "Nama User",
                      field: "nama",
                      sort: "asc",
                      width: 270
                    },
                    {
                      label: "Email",
                      field: "email",
                      sort: "asc",
                      width: 200
                    },
                    {
                      label: "No Telp",
                      field: "no_telp",
                      sort: "asc",
                      width: 100
                    },
                    {
                      label: "",
                      field: "aksi",
                      sort: "asc",
                      width: 100
                    }
                  ],
                  rows: []
            }
        };
        this.tog_standard = this.tog_standard.bind(this);
        this.tog_profile = this.tog_profile.bind(this);
        this.tog_bills = this.tog_bills.bind(this);
        this.tog_block = this.tog_block.bind(this);
        this.tog_unblock = this.tog_unblock.bind(this);
        this.tog_edit = this.tog_edit.bind(this);
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    tog_standard() {
        this.setState(prevState => ({
          modal_standard: !prevState.modal_standard
        }));
        this.removeBodyCss();
    }

    tog_profile(id) {
        this.setState(prevState => ({
            modal_profile: !prevState.modal_profile,
        }));
        const apiUrl = url + 'user/detail/'+id
        if (this.state.modal_profile !== true) {
            this.getUser(apiUrl)
        }
    }

    tog_bills(id, name) {
        this.setState(prevState => ({
            modal_bills: !prevState.modal_bills,
            user_id: id,
            user_name: name
        }));
        this.removeBodyCss();
        if (this.state.modal_bills === false) {
            this.getUserWallet(id)
        }
    }

    tog_block(id) {
        const apiUrl = url + 'user/block?user_id='+id
        Swal.fire({
            title: 'Anda yakin akan mengaktifkan kembali akun ini?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya!',
            cancelButtonText: 'Tidak!',
          }).then((result) => {
            if (result.isConfirmed) {
                this.getBlock(apiUrl)
            }
        })
    }

    tog_unblock(id) {
        const api_url = process.env.REACT_APP_API_URL + 'user/activate?user_id='+id
        Swal.fire({
            title: 'Anda yakin akan mengaktifkan kembali akun ini?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya!',
            cancelButtonText: 'Tidak!',
          }).then((result) => {
            if (result.isConfirmed) {
                this.getActivate(api_url)
            }
        })
    }

    tog_edit(id) {
        this.setState(prevState => ({
            modal_edit: !prevState.modal_edit
        }))
        const data_edit = this.state.all_user.filter(a => a.id === id)[0];
        this.setState({
            edit_name: data_edit?.profile?.full_name,
            edit_noHp: data_edit?.profile?.phone,
            edit_id: id
        })
    }

    getBlock(url) {
        Axios(
            {
                method: 'put', 
                url: url, 
                headers: 
                    {'Authorization': 'Bearer '+getJwt()}
            }
        ).then(res => {
            toast.success(res.data.message)
            this.getAllData();
        })
        .catch(err => {
            if (err.response.status === 422) {
                toast.error(err.response.data.errors[0].message)
            } else if (err.response.status === 500) {
                toast.error('Error Network')
            }
        })
    }

    getActivate(url) {
        Axios(
            {
                method: 'put', 
                url: url, 
                headers: 
                    {'Authorization': 'Bearer '+getJwt()}
            }
        ).then(res => {
            toast.success(res.data.message)
            this.getAllData();
        })
        .catch(err => {
            if (err.response.status === 422) {
                toast.error(err.response.data.errors[0].message)
            } else if (err.response.status === 500) {
                toast.error('Error Network')
            }
        })
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleBill() {
        const apiUrl = url + 'user/update_wallet'
        const obj = {
            user_id: this.state.user_id,
            type: this.state.type,
            money: this.state.money,
            description: this.state.desc
        }
        httpService.put(apiUrl, obj, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            toast.success(res.data.message);
            this.getUserWallet(this.state.user_id)
        })
        .catch(err => {
            if (err.response.status === 422) {
                toast.error(err.response.data.errors[0].message)
            } else if (err.response.status === 500) {
                toast.error('Network Error')
            }
        })
    }

    getUser(url) {
        httpService.get(url, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            this.setState({
                user_profile: res.data.data
            })
        })
    }

    getUserWallet(id) {
        const api_url = url + 'user/walletupdatelog/'+id
        httpService.get(api_url, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            const response = res.data.data;
            const data_wallet = [];
            response.forEach(item => {
                let type;
                if (item.type === 'plus') {
                    type = 'Tambah Dana'
                } else {
                    type = 'Kurangi Dana'
                }
                data_wallet.push({
                    tgl: moment(item.updated_at).format('YYYY-MM-DD HH:mm:ss'),
                    activity: type,
                    desc: item.description,
                    jml: priceSplitter(item.money)
                })
            })
            this.setState({ data_wallet })
        })
    }

    handleEdit() {
        const { edit_id, edit_name, edit_noHp } = this.state;
        const apiUrl = url + 'user/profile?user_id='+edit_id;
        const body = {
            full_name: edit_name,
            phone: edit_noHp
        }
        httpService.put(apiUrl, body, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            toast.success(res.data.message)
            this.getAllData();
            this.setState(prevState => ({
                modal_edit: !prevState.modal_edit
            }))
        })
        .catch(err => {
            if (err.response.status === 404) {
                toast.error(err.response.data.message)
            } 
        })
    }

    getAllData() {
        const apiUrl = url + 'user'
        httpService.get(apiUrl, {
            headers: {
                Authorization: "Bearer "+getJwt()
            }
        })
        .then(res => {
            const allUser = res.data.data;
            const rows = [];
            const data = [];
            allUser.forEach((item, index) => {
                let name = '-';
                let phone = '-';
                if (item.profile !== null) {
                    if (item.profile.full_name !== null) {
                        name = item.profile.full_name
                    }
                    if (item.profile.phone !== null) {
                        phone = item.profile.phone
                    }
                }
                data.push(item)
                rows.push(
                    {
                        no: index+1,
                        tanggal: item.created_at !== null ? moment(item.created_at).format('YYYY-MM-DD HH:mm:ss') : '-',
                        nama: name,
                        email: item.email,
                        no_telp: phone,
                        aksi: 
                            <div className="text-center">
                                <Link to="#" id={'detail'+index}><button type="button" className="btn btn-info waves-effect waves-light mr-2" onClick={() => this.tog_profile(item.id)}><i className="bx bx-show"></i><UncontrolledTooltip placement="top" target={'detail'+index}>View Profile</UncontrolledTooltip></button></Link>
                                <Link to="#" id={"trans"+index}><button type="button" className="btn btn-primary waves-effect waves-light mr-2" onClick={() => this.tog_bills(item.id, name)}><i className="bx bx-dollar"></i><UncontrolledTooltip placement="top" target={"trans"+index}>Transaction</UncontrolledTooltip></button></Link>
                                {item.is_blocked !== true ? 
                                <>
                                    <Link to="#" id={"block"+index}><button type="button" className="btn btn-dark waves-effect waves-light mr-2" onClick={() => this.tog_block(item.id)}><i className="bx bx-user-x"></i><UncontrolledTooltip placement="top" target={"block"+index}>Block User</UncontrolledTooltip></button></Link>
                                </>
                                :
                                <>
                                    <Link to="#" id={"unblock"+index}><button type="button" className="btn btn-light waves-effect waves-light mr-2" onClick={() => this.tog_unblock(item.id)}><i className="bx bx-user-check"></i><UncontrolledTooltip placement="top" target={"unblock"+index}>Unblock User</UncontrolledTooltip></button></Link>
                                </>
                                }
                                <Link to="#" id={"edit"+index}><button type="button" className="btn btn-warning waves-effect waves-light mr-2" onClick={() => this.tog_edit(item.id)}><i className="bx bx-edit-alt"></i><UncontrolledTooltip placement="top" target={"edit"+index}>Edit User</UncontrolledTooltip></button></Link>
                                <Link to="#" id={"delete"+index}><button type="button" className="btn btn-danger waves-effect waves-light mr-2" disabled><i className="bx bx-trash"></i><UncontrolledTooltip placement="top" target={"delete"+index}>Delete User</UncontrolledTooltip></button></Link>
                            </div>
                    }
                )
            })
            this.setState({
                user: {
                    ...this.state.user,
                    rows: rows
                },
                all_user: data
            })
        })
    }

    async componentDidMount() {
        await this.getAllData()
    }

    render() { 
        const { data_wallet, user_profile } = this.state
        return (
            <React.Fragment>
                <ToastContainer />
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="User" breadcrumbItem="User Management" />
                        <Card>
                            <CardBody>
                                <div className="mb-3">
                                    <h5>User List</h5>
                                </div>
                                {/* <button className="btn btn-primary" onClick={this.tog_standard}  data-toggle="modal" data-target="#myModal">Tambah User</button> */}
                                <div className="mt-3">
                                    <MDBDataTable responsive data={this.state.user} />
                                </div>
                                <Modal
                                    isOpen={this.state.modal_profile}
                                    toggle={this.tog_profile}
                                    className="modal-lg"
                                    >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0" id="myModalLabel">
                                        Detail User
                                    </h5>
                                        <button
                                        type="button"
                                        onClick={() =>
                                            this.setState({ modal_profile: false })
                                        }
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        >
                                        <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="avatar-sm d-flex w-100">
                                            <span className="bg-soft-primary rounded-circle avatar-title text-dark" style={{width: '6%', fontSize: '20px'}}><i className="bx bx-user-circle"></i></span>
                                            <h5 style={{width: '50%'}} className="ml-1 mt-3">General Information</h5>
                                        </div>
                                        <div className="mt-3 text-center row">
                                            <Col>
                                               <div style={{fontWeight: 'bold'}}>
                                                    <i className="bx bx-user" style={{fontWeight: 'bold'}}></i>
                                                    <span className="ml-2">Nama User</span>
                                               </div>
                                               <p>{user_profile.profile !== undefined && user_profile.profile.full_name !== null ? user_profile.profile.full_name  : '-'}</p>
                                            </Col>
                                            <Col>
                                                <div style={{fontWeight: 'bold'}}>
                                                    <i className="bx bx-mail-send" style={{fontWeight: 'bold'}}></i>
                                                    <span className="ml-2">Email User</span>
                                               </div>
                                               <p>{user_profile !== undefined && user_profile.email !== null ? user_profile.email  : '-'}</p>
                                            </Col>
                                            <Col>
                                               <div style={{fontWeight: 'bold'}}>
                                                    <i className="bx bx-home" style={{fontWeight: 'bold'}}></i>
                                                    <span className="ml-2">Alamat User</span>
                                               </div>
                                               <p>{user_profile.profile !== undefined && user_profile.profile.address !== null ? user_profile.profile.address  : '-'}</p>
                                            </Col>
                                        </div>
                                        <div className="mt-3 text-center row">
                                            <Col>
                                               <div style={{fontWeight: 'bold'}}>
                                                    <i className="bx bx-phone" style={{fontWeight: 'bold'}}></i>
                                                    <span className="ml-2">Nomor Telp</span>
                                               </div>
                                               <p>{user_profile.profile !== undefined && user_profile.profile.phone !== null ? user_profile.profile.phone  : '-'}</p>
                                            </Col>
                                            <Col>
                                                <div style={{fontWeight: 'bold'}}>
                                                    <i className="bx bx-cake" style={{fontWeight: 'bold'}}></i>
                                                    <span className="ml-2">Tanggal Lahir</span>
                                               </div>
                                               <p>{user_profile.profile !== undefined && user_profile.profile.birthdate !== null ? moment(user_profile.profile.birthdate).format('YYYY-MM-DD')  : '-'}</p>
                                            </Col>
                                            <Col>
                                               <div style={{fontWeight: 'bold'}}>
                                                    <i className="bx bx-id-card" style={{fontWeight: 'bold'}}></i>
                                                    <span className="ml-2">Nomor KTP</span>
                                               </div>
                                               <p>{user_profile.profile !== undefined && user_profile.profile.nomer_ktp !== null ? user_profile.profile.nomer_ktp  : '-'}</p>
                                            </Col>
                                        </div>
                                        <div className="mt-3 text-center row">
                                            <Col>
                                                {user_profile !== undefined && user_profile.activate !== false ? 
                                                    <div className="bg-soft-primary rounded p-1">
                                                        User Telah Teraktifasi
                                                    </div>
                                                :
                                                    <div className="bg-soft-danger rounded p-1">
                                                        User Belum Teraktifasi
                                                    </div>
                                                }
                                            </Col>
                                            <Col>
                                                {user_profile !== undefined && user_profile.is_blocked !== true ? 
                                                    <div className="bg-soft-primary rounded p-1">
                                                        User Tidak Terblokir
                                                    </div>
                                                :
                                                    <div className="bg-soft-danger rounded p-1">
                                                        User Telah Terblokir
                                                    </div>
                                                }
                                            </Col>
                                        </div>
                                        <div className="avatar-sm d-flex w-100 mt-3">
                                            <span className="bg-soft-primary rounded-circle avatar-title text-dark" style={{width: '6%', fontSize: '20px'}}><i className="bx bx-user-check"></i></span>
                                            <h5 style={{width: '50%'}} className="ml-1 mt-3">Verifikasi User</h5>
                                        </div>
                                        <div className="mt-3">
                                            {user_profile.profile !== undefined && user_profile.profile.verification !== null ? 
                                                <div className="w-100 bg-soft-primary text-center rounded p-1">
                                                    User Telah Verifikasi
                                                </div>
                                            :
                                                <div className="w-100 bg-soft-danger text-center rounded p-1">
                                                    User Belum Verifikasi
                                                </div>
                                             }
                                            <div className="mt-3 row">
                                                <Col>
                                                    <Card>
                                                        {user_profile.profile !== undefined && user_profile.profile.photo !== null ? 
                                                            <img src={url+user_profile.profile.photo} alt="" className="avatar-xl w-100" style={{objectFit: 'contain'}} />
                                                        :
                                                            <img src={noImage} alt="" className="avatar-xl w-100" style={{objectFit: 'contain'}}/>
                                                        }
                                                        <p className="text-center mt-3">Photo User</p>
                                                    </Card>
                                                </Col>
                                                <Col>
                                                    <Card>
                                                        {user_profile.profile !== undefined && user_profile.profile.ktp !== null ? 
                                                            <img src={url+user_profile.profile.ktp} alt="" className="avatar-xl w-100" style={{objectFit: 'contain'}} />
                                                        :
                                                            <img src={noImage} alt="" className="avatar-xl w-100" style={{objectFit: 'contain'}} />
                                                        }
                                                        <p className="text-center mt-3">Photo KTP</p>
                                                    </Card>
                                                </Col>
                                                <Col>
                                                    <Card>
                                                        {user_profile.profile !== undefined && user_profile.profile.swafoto_ktp !== null ? 
                                                            <img src={url+user_profile.profile.swafoto_ktp} alt="" className="avatar-xl w-100" style={{objectFit: 'contain'}} />
                                                        :
                                                            <img src={noImage} alt="" className="avatar-xl w-100" style={{objectFit: 'contain'}} />
                                                        }
                                                        <p className="text-center mt-3">Swafoto KTP</p>
                                                    </Card>
                                                </Col>
                                            </div>
                                        </div>
                                        {/* <div className="mt-3 row text-center">
                                            <Col>
                                                <p style={{fontSize: '20px'}}>
                                                    <i className="bx bx-user-circle"></i>
                                                </p>
                                                <p>Name</p>
                                            </Col>
                                            <Col>
                                                <p style={{fontSize: '20px'}}>
                                                    <i className="bx bx-mail-send"></i>
                                                </p>
                                            </Col>
                                            <Col>
                                                <p style={{fontSize: '20px'}}>
                                                    <i className="bx bx-user-check"></i>
                                                </p>
                                                <span className="bg-soft-primary p-1 text-center rounded">User Teraktifasi</span>
                                            </Col>
                                            <Col>
                                                <p style={{fontSize: '20px'}}>
                                                    <i className="bx bx-cake"></i>
                                                </p>
                                            </Col>
                                            <Col>
                                                <p style={{fontSize: '20px'}}>
                                                    <i className="bx bx-home"></i>
                                                </p>
                                            </Col>
                                            <Col>
                                                <p style={{fontSize: '20px'}}>
                                                    <i className="bx bx-phone"></i>
                                                </p>
                                            </Col>
                                        </div> */}
                                        
                                    </div>
                                </Modal>
                                <Modal
                                    isOpen={this.state.modal_bills}
                                    toggle={this.tog_bills}
                                    className="modal-lg"
                                    >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0" id="myModalLabel">
                                        Transaksi
                                    </h5>
                                        <button
                                        type="button"
                                        onClick={() =>
                                            this.setState({ modal_bills: false })
                                        }
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        >
                                        <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <Row>
                                            <Col>
                                                <div className="form-group d-flex">
                                                    <label className="form-label col-md-3 mt-2 pl-0">Nama User</label>
                                                    <input type="text" name="user_name" value={this.state.user_name} className="form-control bg-light" readOnly></input>
                                                </div>
                                                <div className="form-group d-flex">
                                                    <label className="form-label col-md-3 mt-2 pl-0">Saldo User</label>
                                                    <input type="number" name="money" className="form-control" onChange={(e) => this.handleChange(e)} placeholder="Isi Saldo User" required></input>
                                                </div>
                                                <div className="form-group d-flex">
                                                    <label className="form-label col-md-3 mt-2 pl-0">Aktifitas</label>
                                                    <select name="type" className="form-control" onChange={(e) => this.handleChange(e)} value={this.state.type} required>
                                                        <option value="plus">Tambah Dana</option>
                                                        <option value="minus">Kurangi Dana</option>
                                                    </select>
                                                </div>
                                                <div className="form-group d-flex">
                                                    <label className="form-label col-md-3 mt-2 pl-0" >Keterangan</label>
                                                    <textarea type="text" name="desc" onChange={(e) => this.handleChange(e)} className="form-control" placeholder="Isi Keterangan" required></textarea>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="text-right">
                                            <button type="submit" className="btn btn-primary btn-sm" onClick={() => this.handleBill()}>Submit</button>
                                        </div>
                                        <div>
                                            <h6>Rekam Jejak :</h6>
                                            <div style={{ border: '1px solid #52b06d', padding: '10px', borderRadius: '3px', height: '15rem', overflow: 'scroll', whiteSpace: 'nowrap', overflowX: 'hidden'}}>
                                                <table className="table w-100" style={{ fontSize: '11px'}}>
                                                    <thead>
                                                        <tr>
                                                            <th>Tanggal dan Waktu</th>
                                                            <th>Aktifitas</th>
                                                            <th>Keterangan</th>
                                                            <th>Jumlah</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data_wallet.length > 0 ? 
                                                            data_wallet.map(item => {
                                                                return (
                                                                    <tr>
                                                                        <td>{item.tgl}</td>
                                                                        <td>{item.activity}</td>
                                                                        <td>{item.desc}</td>
                                                                        {item.activity === 'Tambah Dana' ? <td className="text-primary">+ {item.jml}</td> : <td className="text-danger">- {item.jml}</td>}
                                                                    </tr>
                                                                )
                                                            })
                                                        : 
                                                            <tr>
                                                                <td colSpan={4} className="text-center">No Data Available!</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                                <Modal
                                    isOpen={this.state.modal_edit}
                                    toggle={this.tog_edit}
                                    className="modal-md"
                                    >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0" id="myModalLabel">
                                        Edit User
                                    </h5>
                                        <button
                                        type="button"
                                        onClick={() =>
                                            this.setState({ modal_edit: false })
                                        }
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        >
                                        <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label className="form-label">Nama User</label>
                                                    <input type="text" name="edit_name" className="form-control" value={this.state.edit_name} onChange={(e) => this.handleChange(e)} placeholder="Masukkan Nama" />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    <label className="form-label">No Tlp</label>
                                                    <input type="number" name="edit_noHp" className="form-control" value={this.state.edit_noHp} onChange={(e) => this.handleChange(e)} placeholder="Masukkan Nomor Telepon" min={0} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="text-right">
                                            <button className="btn btn-primary" onClick={() => this.handleEdit()}>Save</button>
                                        </div>
                                    </div>
                                </Modal>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default User;