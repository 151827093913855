import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logoutUser } from '../../store/actions';
import { logout } from "../../services/authService";

class Logout extends Component {
    /**
     * Redirect to login
     */
    state = {
        jwt: []
    }
    componentDidMount = () => {
        // emit the event
        this.props.logoutUser(this.props.history);
        logout();
        this.props.history.push("/");
    };
    
    render() {
        return <React.Fragment>
        </React.Fragment>;
    }
}

export default withRouter(
    connect(
        null,
        { logoutUser }
    )(Logout)
);
