import React, { Component } from "react";
import { Container, Card, CardBody } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "../../assets/scss/datatables.scss";
import httpService from '../../services/httpService';
import { getJwt } from '../../services/authService';
import moment from 'moment';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 
import '../../assets/css/random.css'

const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g,'.'));

class Transaksi extends Component {
    constructor(props) {
      super(props);
      this.state = {
        start_date: moment().subtract(30, 'days').format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        date_min_di_max: moment("2020-01-01").format("YYYY-MM-DD"),
        date_max_di_min: moment().format("YYYY-MM-DD"),
        key: 'all',
        type: 'all',
        selectedTrans: 'all',
        selectedJenis: 'all',
        selectedTipe: 'all',
        rows_data: []
      }
      this.handleChangeStart = this.handleChangeStart.bind(this);
      this.handleChangeEnd = this.handleChangeEnd.bind(this);
      this.handleTrans = this.handleTrans.bind(this);
      this.handleJenis = this.handleJenis.bind(this);
      this.handleTipe = this.handleTipe.bind(this);
      this.getUnitUsaha = this.getUnitUsaha.bind(this);
      this.getUser = this.getUser.bind(this);
      this.submitDate = this.submitDate.bind(this);
    }

    handleChangeStart(event) {
      let value = event.target.value;
      this.setState({start_date: value});
      this.setState({date_min_di_max: value});
    }

    handleChangeEnd(event) {
      let value = event.target.value;
      this.setState({end_date: value});
      this.setState({date_max_di_min: value});
    }

    handleTrans(value) {
      if (value === 'user') {
        this.getUser(this.state.selectedJenis, this.state.selectedTipe)
      } else if (value === "unit") {
        this.getUnitUsaha(this.state.selectedJenis, this.state.selectedTipe)
      } else {
        this.getAllData(this.state.selectedJenis, this.state.selectedTipe)
      }
      this.setState({ selectedTrans: value })
    }

    handleJenis(value) {
      if (value === 'masuk') {
        if (this.state.selectedTrans === 'user') {
          this.getUser(value, this.state.selectedTipe)
        } else if (this.state.selectedTrans === 'unit') {
          this.getUnitUsaha(value, this.state.selectedTipe)
        } else {
          this.getAllData(value, this.state.selectedTipe)
        }
      } else if (value === "keluar") {
        if (this.state.selectedTrans === 'user') {
          this.getUser(value, this.state.selectedTipe)
        } else if (this.state.selectedTrans === 'unit') {
          this.getUnitUsaha(value, this.state.selectedTipe)
        } else {
          this.getAllData(value, this.state.selectedTipe)
        }
      }
      this.setState({ selectedJenis: value })
    }

    handleTipe(value) {
      if (value === 'topup') {
        if (this.state.selectedTrans === 'user') {
          this.getUser(this.state.selectedJenis, value)
          if (this.state.selectedJenis === 'masuk') {
            this.getUser(this.state.selectedJenis, value)
          } else {
            this.getUser(this.state.selectedJenis, value)
          }
        } else if (this.state.selectedTrans === 'unit') {
          this.getUnitUsaha(this.state.selectedJenis, value)
          if (this.state.selectedJenis === 'masuk') {
            this.getUnitUsaha(this.state.selectedJenis, value)
          } else {
            this.getUnitUsaha(this.state.selectedJenis, value)
          }
        } else {
          if (this.state.selectedJenis === 'masuk') {
            this.getAllData(this.state.selectedJenis, value)
          } else {
            this.getAllData(this.state.selectedJenis, value)
          }
        }
      } else if (value === 'peminjaman') {
        if (this.state.selectedTrans === 'user') {
          this.getUser(this.state.selectedJenis, value)
          if (this.state.selectedJenis === 'masuk') {
            this.getUser(this.state.selectedJenis, value)
          } else {
            this.getUser(this.state.selectedJenis, value)
          }
        } else if (this.state.selectedTrans === 'unit') {
          this.getUnitUsaha(this.state.selectedJenis, value)
          if (this.state.selectedJenis === 'masuk') {
            this.getUnitUsaha(this.state.selectedJenis, value)
          } else {
            this.getUnitUsaha(this.state.selectedJenis, value)
          }
        } else {
          this.getAllData(this.state.selectedJenis, value)
        }
      } else if (value === 'withdrawal') {
        if (this.state.selectedTrans === 'user') {
          this.getUser(this.state.selectedJenis, value)
          if (this.state.selectedJenis === 'masuk') {
            this.getUser(this.state.selectedJenis, value)
          } else {
            this.getUser(this.state.selectedJenis, value)
          }
        } else if (this.state.selectedTrans === 'unit') {
          this.getUnitUsaha(this.state.selectedJenis, value)
          if (this.state.selectedJenis === 'masuk') {
            this.getUnitUsaha(this.state.selectedJenis, value)
          } else {
            this.getUnitUsaha(this.state.selectedJenis, value)
          }
        } else {
          this.getAllData(this.state.selectedJenis, value)
        }
      } else if (value === "iuran") {
        if (this.state.selectedTrans === 'user') {
          this.getUser(this.state.selectedJenis, value)
        } else if (this.state.selectedTrans === 'unit') {
          this.getUnitUsaha(this.state.selectedJenis, value)
        } else {
          this.getAllData(this.state.selectedJenis, value)
        }
      } else if (value === "all") {
        if (this.state.selectedTrans === 'user') {
          this.getUser(this.state.selectedJenis, value)
        } else if (this.state.selectedTrans === 'unit') {
          this.getUnitUsaha(this.state.selectedJenis, value)
        } else {
          this.getAllData(this.state.selectedJenis, value)
        }
      }
      this.setState({ selectedTipe: value })
    }

    isActive(value) {
      return "btn-sm mr-3 btn btn-"+((value===this.state.selectedTrans) ? 'primary' : 'light')
    }

    isActiveJenis(value) {
      return "btn-sm mr-3 btn btn-"+((value===this.state.selectedJenis) ? 'primary' : 'light')
    }

    isActiveTipe(value) {
      return "btn-sm mr-3 btn btn-"+((value===this.state.selectedTipe) ? 'primary' : 'light')
    }

    submitDate() {
      const { selectedTrans, selectedJenis, selectedTipe } = this.state
      if (selectedTrans === "all") {
        this.getAllData(selectedJenis, selectedTipe)
      } else if (selectedTrans === "user") {
        this.getUser(selectedJenis, selectedTipe)
      } else if (selectedTrans === "unit") {
        this.getUnitUsaha(selectedJenis, selectedTipe)
      }
    }

    async getUnitUsaha(key, type) {
      let jenis;
      let tipe;
      if (key !== undefined && type !== undefined) {
        jenis = key
        tipe = type
      } else {
        jenis = this.state.key
        tipe = this.state.type
      }
      const apiUrl = process.env.REACT_APP_API_URL + 'history_transaction/unitUsaha?start_date='+this.state.start_date+'&end_date='+this.state.end_date+'&key='+jenis+'&type='+tipe
      httpService.get(apiUrl, {
        headers: {
            Authorization: 'Bearer '+getJwt()
        }
      })
      .then(res => {
        const response = res.data.data
        const data_rows = []
        response.forEach((item, index) => {
          data_rows.push([index+1, item.name, item.type, 'Rp. '+priceSplitter(item.nominal), item.from, item.to, item.status, moment(item.date).format('YYYY-MM-DD HH:mm:ss')])
        })
        this.getDataTables(data_rows)
        setTimeout(() => {
          this.setState({
            data_rows
          })
        }, 10);
      })
    }

    async getUser(key, type) {
      let jenis;
      let tipe;
      if (key !== undefined && type !== undefined) {
        jenis = key
        tipe = type
      } else {
        jenis = this.state.key
        tipe = this.state.type
      }
      const apiUrl = process.env.REACT_APP_API_URL + 'history_transaction/user?start_date='+this.state.start_date+'&end_date='+this.state.end_date+'&key='+jenis+'&type='+tipe
      httpService.get(apiUrl, {
        headers: {
            Authorization: 'Bearer '+getJwt()
        }
      })
      .then(res => {
        const response = res.data.data
        const data_rows = [];
        response.forEach((item, index) => {
          data_rows.push([index+1, item.name, item.type, 'Rp. '+priceSplitter(item.nominal), item.from, item.to, item.status, moment(item.date).format('YYYY-MM-DD HH:mm:ss')])
        })
        this.getDataTables(data_rows)
        setTimeout(() => {
          this.setState({
            data_rows,
          })
        }, 10);
      })
    }

    async getAllData(key, type) {
      let jenis;
      let tipe;
      if (key !== undefined && type !== undefined) {
        jenis = key
        tipe = type
      } else {
        jenis = this.state.key
        tipe = this.state.type
      }
      const apiUrl = process.env.REACT_APP_API_URL + 'history_transaction/all?start_date='+this.state.start_date+'&end_date='+this.state.end_date+'&key='+jenis+'&type='+tipe
      httpService.get(apiUrl, {
        headers: {
            Authorization: 'Bearer '+getJwt()
        }
      })
      .then(res => {
        const response = res.data.data
        const data_rows = []
        response.forEach((item, index) => {
          data_rows.push([index+1, item.name, item.type, 'Rp. '+priceSplitter(item.nominal), item.from, item.to, item.status, moment(item.date).format('YYYY-MM-DD HH:mm:ss')])
        })
        this.getDataTables(data_rows)
        setTimeout(() => {
          this.setState({
            data_rows,
          })
        }, 10);
      })
    }

    getDataTables(data) {
      $('#tableTrans').DataTable(
        {
          dom: 'lpftrip',
          destroy: true,
          "lengthMenu": [[10, 15, -1], [10, 15, "All"]],
          data: data,
        }
      );
    }

    async componentDidMount() {
      $("#tableTrans").append(
        $('<tfoot/>').append( $("#tableTrans thead tr").clone() )
      );
      await this.getAllData(this.state.key, this.state.type)
    }

    render() { 
      const { date_min_di_max, date_max_di_min, start_date, end_date } = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Cash Flow" breadcrumbItem="Riwayat Transaksi" />
                        <Card>
                            <CardBody>
                                <h5>Riwayat Transaksi</h5>
                                <div className="d-flex mt-3">
                                    <span className="mr-3 mt-2">Periode</span>
                                    <input className="form-control col-md-2 mr-3" type="date" id="date-input-start" min={this.state.date_min} max={date_max_di_min} value={start_date} onChange={this.handleChangeStart}/>
                                    <span className="mt-2 mr-3"> - </span>
                                    <input className="form-control col-md-2 mr-3" type="date" id="date-input-end" min={date_min_di_max} max={this.state.date_max} value={end_date} onChange={this.handleChangeEnd}/>
                                    <button className="btn btn-primary" onClick={this.submitDate}>Apply</button>
                                </div>
                                <table className="w-100 mt-3">
                                  <thead>
                                    <tr>
                                      <td className="text-muted" width="15%">User Transaksi</td>
                                      <td>
                                        <div className="d-flex">
                                            <button className={this.isActive('all')} onClick={() => this.handleTrans('all')}>Semua Transaksi</button>
                                            <button className={this.isActive('user')} onClick={() => this.handleTrans('user')}>User</button>
                                            <button className={this.isActive('unit')} onClick={() => this.handleTrans('unit')}>Unit Usaha</button>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-muted pt-3" width="15%">Jenis Transaksi</td>
                                      <td className="pt-3">
                                        <div className="d-flex">
                                            <button className={this.isActiveJenis('all')} onClick={() => this.handleJenis('all')}>Semua Transaksi</button>
                                            <button className={this.isActiveJenis('masuk')} onClick={() => this.handleJenis('masuk')}>Transaksi Masuk</button>
                                            <button className={this.isActiveJenis('keluar')} onClick={() => this.handleJenis('keluar')}>Transaksi Keluar</button>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-muted pt-3" width="15%">Tipe Transaksi</td>
                                      <td>
                                        <div className="d-flex pt-3">
                                            <button className={this.isActiveTipe('all')} onClick={() => this.handleTipe('all')}>Semua Transaksi</button>
                                            <button className={this.isActiveTipe('topup')} onClick={() => this.handleTipe('topup')}>Top Up</button>
                                            <button className={this.isActiveTipe('peminjaman')} onClick={() => this.handleTipe('peminjaman')}>Peminjaman</button>
                                            <button className={this.isActiveTipe('withdrawal')} onClick={() => this.handleTipe('withdrawal')}>Penarikan</button>
                                            <button className={this.isActiveTipe('iuran')} onClick={() => this.handleTipe('iuran')}>Iuran</button>
                                        </div>
                                      </td>
                                    </tr>
                                  </thead>
                                </table>
                                <div className="mt-4">
                                  <table id="tableTrans" className="table w-100 responsive">
                                    <thead>
                                      <tr>
                                        <th>No</th>
                                        <th>Nama</th>
                                        <th>Tipe Transaksi</th>
                                        <th>Nominal</th>
                                        <th>Asal Dana</th>
                                        <th>Penerima Dana</th>
                                        <th>Jenis Transaksi</th>
                                        <th>Waktu Transaksi</th>
                                      </tr>
                                    </thead>
                                  </table>
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Transaksi;