import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

import Identitas from '../pages/Verifikasi/identitas';
import RiwayatIdentitas from '../pages/Verifikasi/riwayat-identitas';
import UnitUsaha from '../pages/Verifikasi/unit-usaha';
import RiwayatUnit from '../pages/Verifikasi/riwayat-unitusaha';

import Transaksi from '../pages/Cash/transaksi';

import PenarikanUnitUsaha from '../pages/Penarikan/unit-usaha';
import PenarikanUser from '../pages/Penarikan/user';

import Lokasi from '../pages/Unit/lokasi';
import Forum from '../pages/Unit/forum';
import ForumDetail from '../pages/Unit/forum-detail';
import DataUnitUsaha from '../pages/Unit/data_unit';
import Tagihan from "../pages/Unit/tagihan";

import User from '../pages/Management/User';
import User_Log from '../pages/Management/user_log';

import ComingSoon from '../pages/comingsoon';

import History from '../components/CommonForBoth/TopbarDropdown/History'

const authProtectedRoutes = [

	{ path: "/dashboard", component: Dashboard },

	{ path: "/identitas", component: Identitas },
	{ path: "/riwayat-identitas", component: RiwayatIdentitas },
	{ path: "/unit-usaha", component: UnitUsaha },
	{ path: "/riwayat-unitusaha", component: RiwayatUnit },

	{ path: "/penarikan-unitusaha", component: PenarikanUnitUsaha },
	{ path: "/penarikan-user", component: PenarikanUser },

	{ path: "/riwayat-transaksi", component: Transaksi },

	{ path: "/lokasi", component: Lokasi },
	{ path: "/forum", component: Forum },
	{ path: "/forum-detail/:id", component: ForumDetail },
	{ path: "/data-unit-usaha", component: DataUnitUsaha },
	{ path: "/tagihan", component: Tagihan },

	{ path: "/user-management", component: User },
	{ path: "/user-log", component: User_Log },

	{ path: "/history", component: History },

	
	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/login" /> }
];

const publicRoutes = [
	{ path: "/coming-soon", component: ComingSoon },
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
];

export { authProtectedRoutes, publicRoutes };
