import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getJwt } from "../../services/authService";
import httpService from "../../services/httpService";
import { ToastContainer, toast } from 'react-toastify';
import Paginate from 'react-paginate';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/css/pagination.css';
import '../../assets/css/custom.css';
import '../../assets/css/pagination.css';
import Swal from "sweetalert2";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import avatar from '../../assets/images/avatar-comment.png'
import moment from 'moment';

const url = process.env.REACT_APP_API_URL

class ForumDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            all_data: [],
            comment: [],
            attachment: [],
            showComment: false,
            modal_edit: false,
            sortComment: 'old',
            offset: 0,
            perPage: 10,
            currentPage: 0,
            edit_comment: '',
            commentId: '',
            isGallery: false,
            photoIndex: 0,
            images: [],
            role: localStorage.getItem('roleID')
        };
        this.tog_edit = this.tog_edit.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    async getUser() {
        const apiUrl = url + 'user/mydata'
        httpService.get(apiUrl, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            const id = res.data.data.id
            this.setState({ id })
        })
    }

    async getAPI() {
        const { id } = this.props.match.params
        const apiUrl = url + 'forum/'+id
        httpService.get(apiUrl, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            const response = res.data.data
            this.getData(response)
        })
    }

    getData(data) {
        const comment = data.comment.slice(this.state.offset, this.state.offset + this.state.perPage)
        const images  = [];
        data.attachment.forEach(item => {
            images.push(url + item.attachment_url)
        })
        this.setState({
            all_data: data,
            comment: comment,
            attachment: data.attachment,
            pageCount: Math.ceil(data.comment.length / this.state.perPage),
            images
        })
    }

    clickComment() {
        this.setState(prevState => ({
            showComment: !prevState.showComment
        }));
    } 

    changeComment(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    filterComment(e) {
        const value  = e.target.value
        this.setState({
            [e.target.name]: value
        })
        const { all_data } = this.state
        if (value === 'new') {
            all_data.comment.sort((a,b) => {
                const dateA = new Date(a.updated_at)
                const dateB = new Date(b.updated_at)
                return dateB-dateA
            })
        } else if (value === 'old') {
            all_data.comment.sort((a,b) => {
                const dateA = new Date(a.updated_at)
                const dateB = new Date(b.updated_at)
                return dateA-dateB
            })
        }
        this.getData(all_data)
    }

    handleComment(forum_id, unit_id) {
        const apiUrl = url + 'forumComment'
        var obj = {
            forum_id: forum_id,
            unit_usaha_id: unit_id,
            comment: this.state.comment_text,
        }
        httpService.post(apiUrl, obj, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            toast.success(res.data.message)
            this.setState({
                showComment: false
            })
            this.getAPI()
        })
        .catch(err => {
            if (err.response.status === 422) {
                toast.error(err.response.data.errors[0].message)
            } else if (err.response.status === 500) {
                toast.error('Error Network')
            }
        })
    }

    handlePageClick(e) {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.getData(this.state.all_data)
      });
    }

    async componentDidMount() {
        await this.getUser()
        await this.getAPI()
    }

    tog_edit(id) {
        this.setState(prevState => ({
            modal_edit: !prevState.modal_edit,
            commentId: id
        }));
    }

    handleEdit(data) {
        const apiUrl = url + 'forumComment/'+data.id
        const obj = {
            forum_id: data.forum_id,
            unit_usaha_id: data.unit_usaha_id,
            comment: this.state.edit_comment,
        }
        httpService.put(apiUrl, obj, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            toast.success(res.data.message)
            this.setState({
                modal_edit: false
            })
            this.getAPI();
        })
        .catch(err => {
            if (err.response.status === 422) {
                toast.error(err.response.data.errors[0].message)
            } else if (err.response.status === 500) {
                toast.error('Error Network')
            }
        })
    }

    deleteComment(id) {
        var apiUrl = url + 'forumComment/'+id
        Swal.fire({
            title: 'Anda yakin akan mengahpus komentar ini?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya!',
            cancelButtonText: 'Tidak!',
          }).then((result) => {
            if (result.isConfirmed) {
                httpService.delete(apiUrl, {
                    headers: {
                        Authorization: 'Bearer '+getJwt()
                    }
                })
                .then(res => {
                    toast.success(res.data.message)
                    this.getAPI()
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        toast.error(err.response.data.errors[0].message)
                    } else if (err.response.status === 500) {
                        toast.error('Error Network')
                    }
                })
            }
        })
    }

    render() { 
        const { all_data, comment, showComment, sortComment, id, commentId, modal_edit, attachment, isGallery, photoIndex, images, role } = this.state;
        return (
            <React.Fragment>
                <ToastContainer />
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Unit Usaha" breadcrumbItem="Forum Detail" />
                        {isGallery ? (
                            <Lightbox
                                mainSrc={images[photoIndex]}
                                nextSrc={images[(photoIndex + 1) % images.length]}
                                prevSrc={
                                images[(photoIndex + images.length - 1) % images.length]
                                }
                                enableZoom={false}
                                onCloseRequest={() => this.setState({ isGallery: false })}
                                onMovePrevRequest={() =>
                                this.setState({
                                    photoIndex: (photoIndex + images.length - 1) % images.length
                                })
                                }
                                onMoveNextRequest={() =>
                                this.setState({
                                    photoIndex: (photoIndex + 1) % images.length
                                })
                                }
                                imageCaption={"Photo " + parseFloat(photoIndex + 1)}
                            />
                        ) : null}
                        <Row>
                            <Col md="12">
                                <Card>
                                    <CardBody>
                                        <h6 className="text-primary">{all_data.unit_usaha !== undefined ? all_data.unit_usaha.name : ""}</h6>
                                        <h5>{all_data.title}</h5>
                                        <p>{all_data.description}</p>
                                            {attachment.length > 0 ? (
                                                <div className="popup-gallery">
                                                    <div className="img-fluid">
                                                        {attachment.map((elm, key) => 
                                                            <img
                                                                src={url + elm.attachment_url}
                                                                onClick={() =>
                                                                    this.setState({ isGallery: true, photoIndex: key })
                                                                }
                                                                alt=""
                                                                // width="120"
                                                                className="mr-2"
                                                                style={{objectFit: 'contain', borderRadius: '5px', width: '20%'}}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div></div>
                                            )}
                                        <hr></hr>
                                        <Row>
                                            <Col>
                                                <span className="text-muted">{moment(all_data.updated_at).format('YYYY-MM-DD HH:mm')}</span>
                                            </Col>
                                            {role !== "3" ? 
                                                <Col>
                                                    <div className="text-right">
                                                        <div className="replyForum" onClick={() => this.clickComment()}>
                                                            <i className="fas fa-reply"></i>
                                                            <span> Reply</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            : null }
                                        </Row>
                                        {showComment !== false ? (
                                            <div className="mt-2">
                                                <textarea type="text" name="comment_text" className="form-control" placeholder="Isi Komen" onChange={(e) => this.changeComment(e)}></textarea>
                                                <div className="text-right mt-2">
                                                    <button className="btn btn-primary btn-sm" onClick={() => this.handleComment(all_data.id, all_data.unit_usaha_id)}>Submit</button>
                                                </div>
                                            </div>
                                        ) : (
                                            <span></span>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={4} className="mb-3 d-flex">
                                <label className="mt-2">Filter </label>
                                <select className="form-control ml-3" name="sortComment" value={sortComment} onChange={(e) => this.filterComment(e)}>
                                    <option value="old">Komentar Terlama</option>
                                    <option value="new">Komentar Terbaru</option>
                                </select>
                            </Col>
                            <Col sm={8}>
                                <div className="text-right">
                                    <Paginate
                                        previousLabel={"prev"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={this.state.currentPage}
                                    />
                                </div>
                            </Col>
                            {comment.length > 0 ? (
                                <Col sm={12}>
                                    {comment.map(item => 
                                        <div key={item.id} className="row">
                                            <img src={avatar} className="avatar avatar-sm ml-2" alt="" />
                                            <Col>
                                                <Card>
                                                    <Row>
                                                        <Col>
                                                            <h5 className="text-primary ml-3 mt-2">{item.user.profile.full_name != null ? item.user.profile.full_name : 'Anonymous'}</h5>
                                                            <div className="d-flex ml-3 mb-1 text-muted" style={{fontSize: '10px'}}>
                                                                <span>{item.user.member !== null ? item.user.member.role.name : 'Super Admin'} <i className="bx bxs-circle ml-1" style={{fontSize: '7px'}}></i> </span><span className="ml-2">{moment(item.updated_at).format('YYYY-MM-DD HH:mm') }</span> 
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="text-right pt-2 pr-3">
                                                                {item.created_user_id === id ? (
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle href="#" className="card-drop" tag="i">
                                                                            <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu right>
                                                                            <DropdownItem onClick={() => this.tog_edit(item.id)}><i className="fas fa-pencil-alt text-success mr-1"></i>Edit</DropdownItem>
                                                                            <DropdownItem onClick={() => this.deleteComment(item.id)}><i className="fas fa-trash-alt text-danger mr-1"></i>Delete</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                ) : (
                                                                    <span></span>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div className="p-2 pl-3">
                                                        {item.id === commentId && modal_edit !== false ? (
                                                            <div>
                                                                <textarea className="form-control" name="edit_comment" onChange={(e) => this.changeComment(e)} defaultValue={item.comment}></textarea>
                                                                <div className="text-right mt-2">
                                                                    <button className="btn btn-danger btn-sm" onClick={() => this.setState({ modal_edit: false })}>Batal</button>{" "}
                                                                    <button className="btn btn-primary btn-sm" onClick={() => this.handleEdit(item)}>Update</button>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <p>{item.comment}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </Card>
                                            </Col>
                                        </div>
                                    )}   
                                    <div className="text-right">
                                        <Paginate
                                            previousLabel={"prev"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                            forcePage={this.state.currentPage}
                                        />
                                    </div>
                                </Col>
                            ) : (
                                <Col sm={12}>
                                    <div className="text-center">
                                        <span>Comment not found!</span>
                                    </div>
                                </Col>
                            )}
                        </Row>
                       
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ForumDetail;