import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Images
import maintanence from "../assets/images/maintenance.png";

class PagesComingsoon extends Component {
    constructor() {
        super();
        this.renderer.bind(this)
    }

    renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span>You are good to go!</span>;
        } else {
            // Render a countdown
            return <Row><Col>{days}<span>Days</span></Col><Col>{hours}<span>Hours</span></Col><Col>{minutes}<span>Minutes</span></Col><Col>{seconds}<span>Seconds</span></Col></Row>;
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/" className="text-white"><i className="fas fa-home h2"></i></Link>
                </div>

                <div className="my-5 pt-sm-5">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="text-center">
                                    <Row className="justify-content-center mt-5">
                                        <Col sm="4">
                                            <div className="maintenance-img">
                                                <img src={maintanence} alt="" className="img-fluid mx-auto d-block" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <h4 className="mt-5">COMING SOON</h4>
                                    <p className="text-muted">See you</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default PagesComingsoon;