import React, { useEffect, useState } from "react";
import { Container, Card, CardBody, Modal, Row, Col } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import httpService from "../../services/httpService";
import { getJwt } from "../../services/authService";
import { MDBDataTable } from "mdbreact";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2";
import 'sweetalert2/src/sweetalert2.scss';

const Tagihan = () => {
    const [nama_tagihan, set_nama_tagihan] = useState("");
    const [nominal, set_nominal] = useState("");
    const [total, set_total] = useState("");
    const [edit_id, set_edit_id] = useState("");
    const [edit_nama_tagihan, set_edit_nama_tagihan] = useState("");
    const [edit_nominal, set_edit_nominal] = useState("");
    const [edit_total, set_edit_total] = useState("");
    const [rows, setRows] = useState([]);
    const [mod_create, set_mod_create] = useState(false);
    const [mod_edit, set_mod_edit] = useState(false);
    const apiBill = process.env.REACT_APP_API_URL + 'uubill-list';
    const columns = [
        {
            label: "No",
            field: "no",
            sort: "asc",
            width: 50
        },
        {
            label: "Nama Tagihan",
            field: "nama_tagihan",
            sort: "asc",
            width: 150
        }, 
        {
            label: "Nominal",
            field: "nominal",
            sort: "asc",
            width: 150
        },
        {
            label: "Total",
            field: "total",
            sort: "asc",
            width: 150
        },
        {
            label: "Aksi",
            field: "aksi",
            sort: "asc",
            width: 150
        }
    ];

    const create_bill = (e) => {
        e.preventDefault();
        const body = {
            name: nama_tagihan,
            nominal: nominal !== "" ? parseInt(nominal) : 0,
            total: total !== "" ? parseInt(total) : 0,
            is_active: true,
        }
        httpService.post(apiBill, body, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            toast.success('Data Berhasil Ditambahkan!')
            set_mod_create(false);
            get_bill()
        })
        .catch(err => {
            toast.error('Terjadi Kesalahan!')
        })
    }

    const edit_bill = (e, id) => {
        e.preventDefault();
        const api = apiBill + '/' + id;
        const body = {
            name: edit_nama_tagihan,
            nominal: edit_nominal !== "" ? parseInt(edit_nominal) : 0,
            total: edit_total !== "" ? parseInt(edit_total) : 0,
            is_active: true,
        }
        httpService.put(api, body, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            toast.success('Data Berhasil Diupdate!')
            set_mod_edit(false);
            get_bill()
        })
        .catch(err => {
            toast.error('Terjadi Kesalahan!')
        })
    }

    const delete_bill = (id) => {
        const api = apiBill + '/' + id
        httpService.delete(api, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            toast.success('Data berhasil dihapus!');
            get_bill()
        })
        .catch(() => {
            toast.error('Terjadi Kesalahan!');
        })
    }

    const get_bill = () => {
        httpService.get(apiBill, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            const result = "data" in res.data ? res.data.data : [];
            const data = [];
            result.length > 0 && result.forEach((elm, idx) => {
                let btn = <div>
                    <button type="button" className="btn btn-warning btn-sm waves-effect wave-light mx-1" onClick={() => get_bill_by_id(elm.id)}>Edit</button>
                    <button type="button" className="btn btn-danger btn-sm waves-effect wave-light mx-1" onClick={() => tog_delete(elm.id)}>Delete</button>
                </div>
                data.push({
                    no: idx+1,
                    nama_tagihan: elm.name,
                    nominal: elm.nominal,
                    total: elm.total !== null ? elm.total : '-',
                    aksi: btn
                })
            })
            setRows(data);
        })
    } 

    const get_bill_by_id = (id) => {
        const api = apiBill + '/' + id;
        httpService.get(api, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            const result = res.data.data
            set_edit_id(result.id);
            set_edit_nama_tagihan(result.name)
            set_edit_nominal(result.nominal)
            set_edit_total(result.total !== null ? result.total : "")
            tog_edit()
        })
    }

    const tog_create = () => {
        set_mod_create(!mod_create);
    }

    const tog_edit = () => {
        set_mod_edit(!mod_edit);
    }

    const tog_delete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
          }).then((result) => {
            if (result.isConfirmed) {
              delete_bill(id)
            }
          });
    }

    useEffect(() => {
        get_bill();
        // eslint-disable-next-line
    }, [])

    return (
        <React.Fragment>
            <ToastContainer />
            <div className="page-content">
                <Container fluid> 
                    <Breadcrumbs title="Unit Usaha" breadcrumbItem="Harga Tagihan" />
                    <Card>
                        <CardBody>
                            <div className="mb-3 d-flex justify-content-between">
                                <h5>List Harga Tagihan</h5>
                                <button type="button" className="btn btn-primary btn-sm waves-effect wave-light" onClick={() => tog_create()}>+ Tagihan Baru</button>
                            </div>
                            <div className="mt-3">
                                <MDBDataTable responsive data={{columns: columns, rows: rows}} />
                            </div>

                            <Modal
                                isOpen={mod_create}
                                toggle={tog_create}
                                className="modal-lg"
                            >
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0" id="myModalLabel">Tambah Tagihan</h5>
                                    <button
                                        type="button"
                                        onClick={() =>
                                            set_mod_create(false)
                                        }
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <Row>
                                        <Col>
                                            <div className="form-group d-flex">
                                                <label className="form-label col-md-3 mt-2 pl-0">Nama Tagihan</label>
                                                <input type="text" name="nama_tagihan" value={nama_tagihan} className="form-control bg-light" placeholder="Isi Nama Tagihan" onChange={(e) => set_nama_tagihan(e.target.value)}></input>
                                            </div>
                                            <div className="form-group d-flex">
                                                <label className="form-label col-md-3 mt-2 pl-0">Nominal</label>
                                                <input type="number" name="nominal" value={nominal} className="form-control bg-light" placeholder="Isi Nominal" onChange={(e) => set_nominal(e.target.value)}></input>
                                            </div>
                                            <div className="form-group d-flex">
                                                <label className="form-label col-md-3 mt-2 pl-0">Total</label>
                                                <input type="number" name="total" value={total} className="form-control bg-light" placeholder="Isi Total" onChange={(e) => set_total(e.target.value)}></input>
                                            </div>
                                            <div className="text-right">
                                                <button type="button" className="btn btn-primary waves-effect wave-light" onClick={(e) => create_bill(e)}>Submit</button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Modal>

                            <Modal
                                isOpen={mod_edit}
                                toggle={tog_edit}
                                className="modal-lg"
                            >
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0" id="myModalLabel">Edit Tagihan</h5>
                                    <button
                                        type="button"
                                        onClick={() =>
                                            set_mod_edit(false)
                                        }
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <Row>
                                        <Col>
                                            <div className="form-group d-flex">
                                                <label className="form-label col-md-3 mt-2 pl-0">Nama Tagihan</label>
                                                <input type="text" name="nama_tagihan" value={edit_nama_tagihan} className="form-control bg-light" placeholder="Isi Nama Tagihan" onChange={(e) => set_edit_nama_tagihan(e.target.value)}></input>
                                            </div>
                                            <div className="form-group d-flex">
                                                <label className="form-label col-md-3 mt-2 pl-0">Nominal</label>
                                                <input type="number" name="nominal" value={edit_nominal} className="form-control bg-light" placeholder="Isi Nominal" onChange={(e) => set_edit_nominal(e.target.value)}></input>
                                            </div>
                                            <div className="form-group d-flex">
                                                <label className="form-label col-md-3 mt-2 pl-0">Total</label>
                                                <input type="number" name="total" value={edit_total} className="form-control bg-light" placeholder="Isi Total" onChange={(e) => set_edit_total(e.target.value)}></input>
                                            </div>
                                            <div className="text-right">
                                                <button type="button" className="btn btn-primary waves-effect wave-light" onClick={(e) => edit_bill(e, edit_id)}>Update</button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Modal>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Tagihan;