import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, CardText } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getJwt } from "../../services/authService";
import httpService from "../../services/httpService";
import moment from 'moment';
import Swal from 'sweetalert2'
import Paginate from 'react-paginate';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/css/pagination.css';

const url = process.env.REACT_APP_API_URL
var idLocale = require('moment/locale/id'); 
moment.locale('id', idLocale);

class ForumList extends Component {
    constructor(props){
        super(props);
        this.state = {
            showMore: false,
            textShow: 'Show More',
        }
        this.tog_showMore = this.tog_showMore.bind(this);
    }

    tog_showMore() {
        this.setState(prevState => ({
            showMore: !prevState.showMore,
        }));
        if (this.state.showMore === true) {
            this.setState({ textShow: "Show More" })
        } else {
            this.setState({ textShow: "Show Less" })
        }
    }

    render() {
        const { list } = this.props
        const role = localStorage.getItem('roleID');
        const date = moment(list.created_at).format('LL')
        let lock;
        let color;

        if (role !== "3" ) {
            // console.log('ada')
            if (list.is_lock === true) {
                lock = <button className="btn btn-danger btn-sm" onClick={() => this.props.handleLock(list)}><i className="fa fa-lock"></i></button>
                color = "danger"
            } else {
                lock = <button className="btn btn-primary btn-sm" onClick={() => this.props.handleLock(list)}><i className="fa fa-lock-open"></i></button>
                color = "primary"
            }
        }
        return (
            <Col md={4} key={list.id}>
                <Card outline color={color} className="border" onClick={() => this.props.handleDetail(list.id)} style={{cursor: 'pointer'}}>
                    <CardBody>
                        <Row>
                            <Col sm={10}>
                                <CardTitle className="mt-0">{list.title}</CardTitle>
                            </Col>
                            <Col sm={2}>
                            <div className="text-right">
                                {lock}
                            </div>
                            </Col>
                        </Row>
                        <CardText>
                            {this.state.showMore ? list.description : `${list.description.substring(0, 250)}`}
                        </CardText>
                        {list.description.length > 250 ? (
                            <p className="text-primary" style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={this.tog_showMore}>{this.state.textShow}</p>
                        ) : (
                            <span></span>
                        )}
                        <span className="text-muted">{date}</span>
                    </CardBody>
                </Card>
            </Col>  
        )
    }
}

class Forum extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // startDate: moment().subtract(30, 'days').format("YYYY-MM-DD"),
            // endDate: moment().format("YYYY-MM-DD"),
            startDate: "",
            endDate: "",
            date_min_di_max: moment("2020-01-01").format("YYYY-MM-DD"),
            date_max_di_min: moment().format("YYYY-MM-DD"),
            lock: "",
            key: "",
            orderby: "",
            status: "",
            time: "",
            data: [],
            dataForum: [],
            filter: '',
            offset: 0,
            perPage: 21,
            currentPage: 0
        };
        this.getLock = this.getLock.bind(this);
        this.handleStart = this.handleStart.bind(this);
        this.handleEnd = this.handleEnd.bind(this);
        this.handleStatus = this.handleStatus.bind(this);
        this.handleTime = this.handleTime.bind(this);
        this.getData = this.getData.bind(this);
        this.searchFilter = this.searchFilter.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.submitFilter = this.submitFilter.bind(this);
    }

    async getData(s, e, lock, key, orderby) {
        const apiUrl = url + 'forum?start_date='+s+'&end_date='+e+'&lock='+lock+'&key='+key+'&orderby='+orderby;
        httpService.get(apiUrl, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            const data = res.data.data
            const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
                dataForum: data
            })
            this.getIsi(slice)
        })
    }
    
    getIsi(value) {
        this.setState({ data: value })
    }

    async componentDidMount() {
        const { startDate, endDate, lock, key, orderby } = this.state
        await this.getData(startDate, endDate, lock, key, orderby)
    }

    handleStart(e) {
        let value = e.target.value;
        this.setState({ startDate: value });
        this.setState({ date_min_di_max: value });
    }

    handleEnd(e) {
        let value = e.target.value;
        this.setState({ endDate: value });
        this.setState({ date_max_di_min: value });
    }

    isActiveStatus(value) {
        return "btn-sm mr-3 btn btn-"+((value===this.state.lock) ? 'primary' : 'light')
    }

    isActiveForum(value) {
        return "btn-sm mr-3 btn btn-"+((value===this.state.orderby) ? 'primary' : 'light')
    }

    isActiveTime(value) {
        return "btn-sm mr-3 btn btn-"+((value===this.state.key) ? 'primary' : 'light')
    }

    handleStatus(val) {
        const { startDate, endDate, key, orderby } = this.state
        this.setState({ lock: val })
        this.getData(startDate, endDate, val, key, orderby)
    }

    handleForum(val) {
        this.setState({ orderby: val })
        const {startDate, endDate, lock, key} = this.state
        this.getData(startDate, endDate, lock, key, val)
    }

    handleTime(val) {
        this.setState({ key: val })
        const { startDate, endDate, lock, orderby } = this.state
        this.getData(startDate, endDate, lock, val, orderby )
    }

    getLock(value) {
        const apiUrl = process.env.REACT_APP_API_URL + 'forum/lock/'+value.id
        if (value.is_lock === true) {
            Swal.fire({
                title: 'Anda yakin akan membuka kunci forum ini?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya!',
                cancelButtonText: 'Tidak!',
              }).then((result) => {
                if (result.isConfirmed) {
                    const obj = {
                        is_lock: false
                    }
                    httpService.put(apiUrl, obj, {
                        headers: {
                            Authorization: 'Bearer '+getJwt()
                        }
                    })
                    .then(res => {
                        this.getData()
                        toast.success(res.data.message)
                    })
                    .catch(err => {
                        if (err.response) {
                            toast.error(err.response.data.message)
                        } else {
                            toast.error("Network Error")
                        }
                    })
                }
            })
        } else {
            Swal.fire({
                title: 'Anda yakin akan mengunci forum ini?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya!',
                cancelButtonText: 'Tidak!',
              }).then((result) => {
                if (result.isConfirmed) {
                    const obj = {
                        is_lock: true
                    }
                    httpService.put(apiUrl, obj, {
                        headers: {
                            Authorization: 'Bearer '+getJwt()
                        }
                    })
                    .then(res => {
                        this.getData()
                        toast.success(res.data.message)
                    })
                    .catch(err => {
                        if (err.response) {
                            toast.error(err.response.data.message)
                        } else {
                            toast.error("Network Error")
                        }
                    })
                }
            })
        }
    }

    getDetail(id) {
        this.props.history.push('/forum-detail/'+id)
    }

    handlePageClick(e) {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            const {startDate, endDate, lock, key, orderby} = this.state
            this.getData(startDate, endDate, lock, key, orderby)
      });
    }

    searchFilter(e) {
        this.setState({ filter: e.target.value })
    }

    submitFilter() {
        const { startDate, endDate, lock, key, orderby } = this.state
        this.getData(startDate, endDate, lock, key, orderby)
    }

    render() { 
        const { data, startDate, endDate, date_min_di_max, date_max_di_min, filter } = this.state
        const lowercasedFilter = filter.toLowerCase();
        const filteredData = data.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(lowercasedFilter)
            );
        });
        return (
            <React.Fragment>
                <ToastContainer />
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Unit Usaha" breadcrumbItem="Forum" />
                        <Row>
                            <Col md="12">
                                <Card>
                                    <CardBody>
                                        <h6>Forum</h6>
                                        <table className="w-100 mt-3">
                                            <thead>
                                                <tr>
                                                    <td className="text-muted" width="10%">Filter Status</td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <button className={this.isActiveStatus('')} onClick={() => this.handleStatus('')}>Semua</button>
                                                            <button className={this.isActiveStatus('true')} onClick={() => this.handleStatus('true')}>Lock</button>
                                                            <button className={this.isActiveStatus('false')} onClick={() => this.handleStatus('false')}>Unlock</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-muted pt-3" width="10%">Filter Forum</td>
                                                    <td className="pt-3">
                                                        <div className="d-flex">
                                                            <button className={this.isActiveForum('')} onClick={() => this.handleForum('')}>Semua</button>
                                                            <button className={this.isActiveForum('desc')} onClick={() => this.handleForum('desc')}>Terbaru</button>
                                                            <button className={this.isActiveForum('asc')} onClick={() => this.handleForum('asc')}>Terakhir</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-muted pt-3" width="10%">Waktu Unggah</td>
                                                    <td className="pt-3">
                                                        <div className="d-flex">
                                                            <button className={this.isActiveTime('')} onClick={() => this.handleTime('')}>Semua</button>
                                                            <button className={this.isActiveTime('created_at')} onClick={() => this.handleTime('created_at')}>Terakhir Dibuat</button>
                                                            <button className={this.isActiveTime('updated_at')} onClick={() => this.handleTime('updated_at')}>Terakhir Diupdate</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </thead>
                                        </table>
                                        <Row>
                                            <Col sm={10}>
                                                <div className="d-flex mt-3">
                                                    <span className="mt-2 mr-3">Periode</span>
                                                    <input type="date" className="form-control col-md-3" min={this.state.date_min} max={date_max_di_min} value={startDate} onChange={this.handleStart}></input>
                                                    <span className="mt-2 ml-3 mr-3">-</span>
                                                    <input type="date" className="form-control col-md-3" min={date_min_di_max} max={this.state.date_max} value={endDate} onChange={this.handleEnd}></input>
                                                    <button className="btn btn-primary ml-3" onClick={() => this.submitFilter()}>Apply</button>
                                                </div>
                                            </Col>
                                            <Col sm={2}>
                                                <div className="text-right mt-3">
                                                    <input type="text" className="form-control" placeholder="Search" value={filter} onChange={this.searchFilter}></input>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            {filteredData.length > 0 ? (
                                                filteredData.map((item, index) => <ForumList list={item} key={index} handleLock={() => this.getLock(item)} handleDetail={() => this.getDetail(item.id)} /> )
                                            ) : (
                                                <Col>
                                                    <Card outline color="primary" className="border">
                                                        <h6 className="text-center p-1 mt-2">No Data Available</h6>
                                                    </Card>
                                                </Col>
                                            )}
                                        </Row>
                                        <Paginate
                                            previousLabel={"prev"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Forum;