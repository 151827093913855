import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class Transaction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    toolbar: {
                        show: false,
                    }
                },
                colors: ['#FFE454', '#6CBC83', '#BFBFBF'],
                plotOptions: {
                    bar: {
                        columnWidth: '35%',
                        endingShape: 'rounded',
                        dataLabels: {
                            show: false
                        }
                    }
                },
                legend: {
                    position: 'top'
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    borderColor: '#f8f8fa',
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    }
                },
                stroke: {
                    show: true,
                    width: 1.5,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'],
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
            }
        }
    }
    render() {
        const { data } = this.props
        return (
            <React.Fragment>
                <ReactApexChart options={this.state.options} series={data} type="bar" height="290" />
            </React.Fragment>
        );
    }
}

export default Transaction;
