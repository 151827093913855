import React, { Component } from 'react';
import { Row, Col, CardBody, Card } from "reactstrap";
import { getJwt, login } from "../../services/authService";
// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';
// actions
import { loginUser,apiError } from '../../store/actions';
// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo_circle.png";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            loggedIn: '',
            error: ''
        }

        //handleChange
        this.onChange = this.onChange.bind(this);

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    //handleChange
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {

        this.props.loginUser(values, this.props.history);

        login(this.state.email, this.state.password).then( (res) => {
            this.props.history.push("/dashboard");
        })
        .catch(error => {  
            toast.warning(error.response.data.message)       
        })
    }

    componentDidMount()
    {
        if (getJwt() !== null) {
            this.props.history.push("/dashboard")
        } else {
            this.props.history.push("/login")
        }
        this.props.apiError("");
    }

    render() {
        return (
            <React.Fragment>
                <ToastContainer />
                <div className="account-pages my-5 pt-sm-5">
                    <div className="container">
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="bg-soft-primary">
                                        <Row>
                                            <Col className="col-8">
                                                <div className="text-primary p-4">
                                                    <h6 className="text-primary">Welcome to Bu Lurah Dashboard!</h6>
                                                    <p>Please Sign in.</p>
                                                </div>
                                            </Col>
                                            <Col className="col-4 align-self-end">
                                                <img src={profile} alt="" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div>
                                            <Link to="/">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <span className="avatar-title rounded-circle bg-light">
                                                        <img src={logo} alt="" className="rounded-circle header-profile-user" style={{backgroundColor: 'transparent', width: '75px', height: '75px'}} height="100" />
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div>

                                            <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>

                                                {/* {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null} */}

                                                <div className="form-group">
                                                    <AvField name="email" label="Email" value={this.state.email} onChange={this.onChange} className="form-control" placeholder="Enter email" type="email" required />
                                                </div>

                                                <div className="form-group">
                                                    <AvField name="password" label="Password" type="password" value={this.state.password} onChange={this.onChange} required placeholder="Enter Password" />
                                                </div>

                                                {/* <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                    <label className="custom-control-label" htmlFor="customControlInline">Remember me</label>
                                                </div> */}

                                                <div className="mt-3">
                                                    <button className="btn btn-primary btn-block waves-effect waves-light" type="submit">Log In</button>
                                                </div>

                                                {/* <div className="mt-2 text-center">
                                                    <Link to="/forget-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                                </div> */}
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { error } = state.Login;
    return { error };
}

export default withRouter(connect(mapStatetoProps, { loginUser,apiError })(Login));

